import React from 'react';

import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
    en: {
        notFound: 'Page not found',
    },
    de: {
        notFound: 'Seite wurde nicht gefunden',
    },
});

const NotFoundPage = () => <>{strings.notFound}</>;

export default NotFoundPage;
